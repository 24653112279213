/**
 * Header
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { red500 } from '../styles/colors';
import zzcLogo from '../ZZ-Circuit-logo.png';

const Wrapper = styled.header`
  border-bottom: 1px solid ${red500};
`;

const Logo = styled.img`
  height: 24px;
  margin-right: 10px;
`;

function Header(props) {
  const { children } = props;
  return (
    <Wrapper className="p-3 mb-3 p-sm-4 mb-sm-3">
      <h1 className="d-flex justify-content-start m-0">
        <Link className="homeLink" to="/">
          <Logo src={zzcLogo} alt="Logo Zorg en Zekerheid Circuit" />
        </Link>
        <span>
          {children}
        </span>
      </h1>
    </Wrapper>
  );
}

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
