function getItems(elem) {
  const href = elem.getAttribute('href');
  const [ID, raceID] = href.match(/ID=(\d+)&RID=(\d+)/).slice(1, 3);
  const [day, mo, yr] = elem.querySelector('.tekstdag').textContent.split('-').map(Number);
  const date = new Date(2000 + yr, mo - 1, day);
  const name = elem.querySelector('.tekstmaand').innerHTML.split('<br>').map((s) => s.trim()).join(', ');
  return {
    pathname: `/race_${raceID}`,
    disabled: date > new Date(),
    name,
    date,
    ID,
    raceID,
  };
}

export default getItems;
