import { ZZC_RACE, ZZZ_CIRCUIT } from '../constants';
import fetchDOM from './fetchDOM';

function createElement(tag, text) {
  const element = document.createElement(tag);
  element.textContent = text;
  return element;
}

function addRanking(table) {
  const tHead = table.querySelector('thead');
  if (!tHead) {
    return;
  }
  tHead.querySelector('tr').insertAdjacentElement('afterbegin', createElement('th', '#Cat'));
  let rank = 1;
  Array.from(table.querySelectorAll('tbody tr')).forEach((row) => {
    // eslint-disable-next-line no-plusplus
    row.insertAdjacentElement('afterbegin', createElement('td', rank++));
  });
}

async function fetchResults(raceID) {
  if (raceID === 0) {
    const dom = await fetchDOM(ZZZ_CIRCUIT);
    const tables = dom.querySelectorAll('table');
    tables.forEach(addRanking);
    return dom;
  }
  return fetchDOM(`${ZZC_RACE}&RaceID=${raceID}`);
}

export default fetchResults;
