import { createGlobalStyle } from 'styled-components';
import {
  green500, red500, blue500, orange500, lightBlack, darkBlack,
} from './colors';

const GlobalStyle = createGlobalStyle`
  #root {
    color: ${darkBlack};
    .table {
      color: ${darkBlack};
      th {
        color: ${lightBlack};
      }
    }
  }
  h1 {
    font-size: 1.1em;
    font-weight: 500;
    color: ${lightBlack};
  }
  h2 {
    font-size: 1.2em;
    font-weight: 500;
    color: ${lightBlack};
  }
  h3 {
    font-size: 1.0em;
    color: ${lightBlack};
  }
  a:not(:hover) {
    text-decoration: none;
  }
  .bg-success {
    background-color: ${green500}!important;
  }
  .bg-warning {
    background-color: ${orange500}!important;
  }
  .bg-danger {
    background-color: ${red500}!important;
  }
  .bg-primary {
    background-color: ${blue500}!important;
  }
`;

export default GlobalStyle;
