/**
 * TabLines
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import TabSelect from './TabSelect';
import SearchForm from './SearchForm';

function TabLines(props) {
  const { results, hash } = props;
  const resultTitleParts = [];
  if (hash.top) {
    resultTitleParts.push(results.querySelector(`a[href="${hash.top}"]`).text);
  }
  if (hash.sub) {
    resultTitleParts.push(results.querySelector(`a[href="${hash.sub}"]`).text);
  }
  if (hash.search) {
    resultTitleParts.push(`Zoekresultaten voor '${hash.search}'`);
  }
  const resultTitle = resultTitleParts.join(' - ');
  return (
    <>
      <TabSelect results={results} id="#tabsDisp" active={hash.top} search="av sparta" />
      {hash.top && <TabSelect results={results} id={hash.top} hash={hash.sub} active={hash.sub} />}
      {!hash.sub && <SearchForm search={hash.search} />}
      {resultTitle && <h2 className="sr-only">{resultTitle}</h2>}
    </>
  );
}

TabLines.propTypes = {
  results: PropTypes.object,
  hash: PropTypes.object,
};

export default TabLines;
