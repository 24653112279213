/**
 * SearchResults
 */

/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Table } from 'react-bootstrap';
import getLinks from '../../utils/getLinks';

function SearchResults(props) {
  const { results, search } = props;
  const hasText = (elem) => search && elem.innerText.toLowerCase().includes(search.toLowerCase());
  const thead = results.querySelector('thead');
  if (!search || !thead) {
    return null;
  }
  const numCols = thead.querySelectorAll('th').length;

  function getCategories(distance) {
    const categories = getLinks(results.querySelector(`${distance.hash} ul`));
    if (categories[0].name === 'Overall') {
      return [{ ...categories[0], label: distance.name }];
    }
    return categories.map((cat) => ({
      ...cat,
      label: <span>{`${distance.name} - ${cat.name}`}</span>,
    }));
  }

  function renderForCategory(category) {
    const trows = Array.from(results.querySelectorAll(`${category.hash} tbody tr`)).filter(hasText);
    if (trows.length === 0) {
      return null;
    }
    const rows = trows.map((row) => <tr key={row.innerHTML} dangerouslySetInnerHTML={{ __html: row.innerHTML }}></tr>);
    return [<tr key={category.hash} className="tr-header"><td colSpan={numCols}><h3>{category.label}</h3></td></tr>, ...rows];
  }

  function renderForDistance(distance) {
    const categories = getCategories(distance);
    return categories.map(renderForCategory).filter(Boolean).flat();
  }

  if (!search) {
    return null;
  }
  const distances = getLinks(results.querySelector('#tabsDisp ul'));
  const rows = distances.map(renderForDistance).flat();
  if (rows.length === 0) {
    return (
      <Alert variant="danger">{`No results for ${search}`}</Alert>
    );
  }

  return (
    <Table size="sm" responsive>
      <thead dangerouslySetInnerHTML={{ __html: thead.innerHTML }}></thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
}

SearchResults.propTypes = {
  results: PropTypes.object,
  search: PropTypes.string,
};

export default SearchResults;
