/**
 * ResultTable
 */

/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Alert, Table } from 'react-bootstrap';

function ResultTable(props) {
  const { results, hash } = props;
  const table = results.querySelector(`${hash} Table`);
  if (!table) {
    return <Alert variant="danger">No results yet</Alert>;
  }
  const thead = table.querySelector('thead')?.innerHTML;
  const tbody = Array.from(table.querySelectorAll('tbody')).map((elem) => elem.innerHTML).join('');
  return (
    <Table size="sm" responsive>
      <thead dangerouslySetInnerHTML={{ __html: thead }}></thead>
      <tbody dangerouslySetInnerHTML={{ __html: tbody }}></tbody>
    </Table>
  );
}

ResultTable.propTypes = {
  results: PropTypes.object,
  hash: PropTypes.string,
};

export default ResultTable;
