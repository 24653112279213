/**
 * useHash
 */

import { useLocation } from 'react-router-dom';

function useHash() {
  const { hash } = useLocation();
  const parts = hash.slice(1).split(/[-=]/);
  if (parts.length === 0) {
    return {};
  }
  switch (parts[0]) {
    case 'tabs':
      return { top: hash };
    case 'tbCat': {
      const distance = parts[1];
      const sex = parts[parts.length - 1];
      return {
        top: `#tabs-${distance}-${sex.toLowerCase()}`,
        sub: hash,
      };
    }
    case 'search':
      return { search: decodeURIComponent(parts[1]) };
    default:
      return {};
  }
}

export default useHash;
