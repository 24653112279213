/**
 * useTableHeight
 */

import { useState, useEffect } from 'react';

function useTableHeight(results, hash) {
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    const table = document.querySelector('.table-responsive');
    if (table) {
      const previous = table.previousElementSibling;
      if (previous) {
        const footer = document.querySelector('#root > footer');
        const height = window.innerHeight - previous.offsetTop - previous.offsetHeight - footer.offsetHeight;
        setTableHeight(height);
        table.scrollTop = 0;
      }
    }
  }, [results, hash]);
  return tableHeight;
}

export default useTableHeight;
