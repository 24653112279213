/**
 * useResults
 */

import { useState, useEffect } from 'react';
import fetchResults from '../../utils/fetchResults';

function useResults(pathname) {
  const [results, setResults] = useState('loading');
  useEffect(() => {
    fetchResults(pathname)
      .then(setResults)
      .catch(((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        setResults(error.message);
      }));
  }, [pathname]);
  return results;
}

export default useResults;
