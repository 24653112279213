/**
 * Home
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

function Home(props) {
  const { items } = props;
  const { push } = useHistory();
  return (
    <main>
      <ListGroup variant="flush">
        {items.map((item) => (
          <ListGroup.Item key={item.name} action onClick={() => push(item.pathname)} disabled={item.disabled}>{item.name}</ListGroup.Item>
        ))}
      </ListGroup>
    </main>
  );
}

Home.propTypes = {
  items: PropTypes.array,
};

export default Home;
