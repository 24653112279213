import { css } from 'styled-components';

const sidePadding = css`
  padding-left: 15px;
  padding-right: 15px;
  @media screen and (max-width: 479px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export default sidePadding;
