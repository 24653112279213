/**
 * SearchForm in results
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { Button, Form } from 'react-bootstrap';
import sidePadding from '../../styles/sidePadding';

const FlexForm = styled(Form)`
  ${sidePadding};
  display: flex;
  margin-bottom: 1rem;
  input.form-control {
    margin-right: 1rem;
  }
  .btn {
    min-width: 4rem;
  }
`;

function SearchForm(props) {
  const { search } = props;
  const handleSubmit = useCallback((ev) => {
    ev.preventDefault();
    const str = ev.target.elements.search.value;
    if (str.length > 0) {
      window.location.hash = `#search=${encodeURIComponent(str)}`;
    }
  }, []);
  return (
    <FlexForm onSubmit={handleSubmit}>
      <Form.Control type="search" name="search" defaultValue={search} aria-label="search" placeholder="naam of vereniging/plaats" />
      <Button variant="primary" type="submit" aria-label="search">
        <FaSearch />
      </Button>
    </FlexForm>
  );
}

SearchForm.propTypes = {
  search: PropTypes.string,
};

export default SearchForm;
