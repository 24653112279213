/**
 * Results
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, Spinner } from 'react-bootstrap';
import ResultTable from './ResultTable';
import sidePadding from '../../styles/sidePadding';
import { yellowA100, darkBlack } from '../../styles/colors';
import SearchResults from './SearchResults';
import useResults from './useResults';
import TabLines from './TabLines';
import useTableHeight from './useTableHeight';
import useHash from './useHash';

const Main = styled.main`
  @media screen and (max-width: 767px) { 
    font-size: smaller;
  }
  .table-responsive {
    max-height: ${(p) => p.tableHeight}px;
    overflow-y: auto;
    margin-bottom: -12px;
  }
  & > ul, .table-responsive th:first-child, .table-responsive td:first-child {
    ${sidePadding};
  }
  .tr-header {
    background-color: ${yellowA100};
    color: ${darkBlack};
  }
  h2, h3 {
    margin-bottom: 0;
  }
`;

function Results(props) {
  const { item } = props;
  const results = useResults(item.raceID);
  const hash = useHash();
  const tableHeight = useTableHeight(results, hash);
  if (results === 'loading') {
    return (
      <main className="text-center m-3 m-sm-4">
        <Spinner className="text-center" variant="secondary" animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </main>
    );
  }
  if (typeof results === 'string') {
    return (
      <main className="m-3 m-sm-4">
        <Alert variant="danger">{results}</Alert>
      </main>
    );
  }
  return (
    <Main tableHeight={tableHeight}>
      <TabLines results={results} hash={hash} />
      {hash.sub
        ? <ResultTable results={results} hash={hash.sub} />
        : <SearchResults results={results} search={hash.search} />}
    </Main>
  );
}

Results.propTypes = {
  item: PropTypes.object,
};

export default Results;
