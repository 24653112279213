import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import GlobalStyle from './styles/GlobalStyles';
import Results from './components/Results';
import Home from './components/Home';
import Footer from './components/Footer';
import useMenu from './hooks/useMenu';
import Header from './components/Header';

function App() {
  const items = useMenu();
  return (
    <Router>
      <GlobalStyle />
      <Switch>
        {items.map((item) => (
          <Route key={item.pathname} path={item.pathname}>
            <Header>{item.name}</Header>
            <Results item={item} />
          </Route>
        ))}
        <Route>
          <Header>Uitslagen</Header>
          <Home items={items} />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
