/**
 * TabSelect
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import spartaLogo from './sparta.png';
import getLinks from '../../utils/getLinks';
import Tabs from './Tabs';

function TabSelect(props) {
  const {
    results, id, search, active,
  } = props;
  const { hash } = useLocation();
  const ul = results.querySelector(`${id} ul`);
  const items = getLinks(ul);
  const isActive = (item) => active === item.hash;
  const searchHash = search && `#search=${encodeURIComponent(search)}`;
  return (
    <Tabs role="navigation">
      { search && <li><a className={hash === searchHash ? 'active' : ''} href={searchHash}><img src={spartaLogo} alt="Search AV Sparta" /></a></li> }
      {items.map((item) => (
        <li key={item.hash}><a className={isActive(item) ? 'active' : ''} href={item.hash}>{item.name}</a></li>
      ))}
    </Tabs>
  );
}

TabSelect.propTypes = {
  results: PropTypes.object,
  id: PropTypes.string,
  active: PropTypes.string,
  search: PropTypes.string,
};

export default TabSelect;
