/**
 * Tabs
 */

import styled from 'styled-components';
import {
  faintBlack, green500, white, darkBlack,
} from '../../styles/colors';

const Tabs = styled.ul.attrs({ className: 'list-unstyled' })`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  max-width: 100%;
  overflow: auto;
  li {
    flex-grow: 1;
    a {
      padding: 10px;
      overflow: hidden;
      text-align: center;
      display: block;
      height: 100%;
      border: 1px solid ${faintBlack};
      color: ${darkBlack};
      &:hover, &:focus {
        background-color: ${faintBlack};
        text-decoration: none;
      }
      &.active {
        color: ${white};
        background-color: ${green500}!important;
      }
      img {
        height: 24px;
        margin: -2px 0;
      }
    }
    &:not(:last-child) a {
      border-right: none;
    }
  }
  @media screen and (max-width: 479px) {
    li a {
      padding: 5px;
    }
  }

`;

export default Tabs;
