/**
 *  Footer
 */

import React from 'react';
import styled from 'styled-components';
import { green500, lightBlack } from '../styles/colors';

const Wrapper = styled.footer`
    position: fixed;
    bottom: 0;
    border-top: 1px solid ${green500};
    color: ${lightBlack};
    font-size: smaller;
`;

const Img = styled.img`
    height: 12px;
    transform: translateY(-2px);
`;

export default function Footer() {
  return (
    <Wrapper className="p-3 mt-3 p-sm-4 mt-sm-4 w-100 text-center">
      Developed by Harry Oosterveen,
      <a href="https://www.harryonline.net/" target="_blank" rel="noopener noreferrer" className="fst-italic text-nowrap">
        <span className="mx-2">HarryOnline</span>
        <Img alt="HarryOnline logo" src="https://s3-eu-west-1.amazonaws.com/logos.harryonline/ho_logo_24x26.png" />
      </a>
    </Wrapper>

  );
}
