/**
* useMenu
* Read links to uitslagen from menu on home page
*
*/
import { useState, useEffect } from 'react';
import fetchDOM from '../utils/fetchDOM';
import { ZZC_BASE } from '../constants';
import getItems from '../utils/getItems';

async function getRaceItems() {
  const dom = await fetchDOM(ZZC_BASE);
  const elements = dom.querySelectorAll('.run_ a');
  return Array.from(elements).map(getItems);
}

async function getEdition() {
  return {
    pathname: '/race_0',
    name: 'Stand',
    raceID: 0,
  };
}

function useMenu() {
  const [menu, setMenu] = useState([]);

  async function getAllItems() {
    const items = await getRaceItems();
    const edition = await getEdition();
    setMenu([edition, ...items]);
  }

  useEffect(() => {
    getAllItems();
  }, []);
  return menu;
}

export default useMenu;
