import 'whatwg-fetch';
import iconv from 'iconv-lite';

const PROXY_URL = 'https://proxy.harryonline.net/';
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
async function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('Content-Type');
  if (contentType === 'application/x-javascript') {
    const data = await response.text();
    const match = data.match(/^\w*\((.*)\)\W*$/);
    return match ? JSON.parse(match[1]) : data;
  }
  if (/json/.test(contentType)) {
    return response.json();
  }
  const match = contentType.match(/charset=(\S+)/);
  const charset = match && match[1];
  if (!charset || /utf/i.test(charset)) {
    return response.text();
  }
  const buffer = await response.arrayBuffer();
  return iconv.decode(Buffer.from(buffer), charset);
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText || response.status);
  error.response = response;
  throw error;
}

/**
 * Convert object to URL query string
 * @param {*} options
 */
function serialize(options) {
  return Object.keys(options).map((key) => (`${key}=${encodeURI(options[key])}`)).join('&');
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request({ url, query, method = 'GET' }) {
  const fullUrl = query ? `${url}?${serialize(query)}` : url;
  const headers = { 'X-Proxy-URL': fullUrl, 'X-Proxy-Cache': 900, 'Accept-Charset': 'utf8' };
  return fetch(PROXY_URL, { method, headers })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
}
